import { reject } from "lodash"

export function fetchEditPOSPageData(id:number){

    const {get}=useOFetchCustom(`point_of_sales/${id}/edit`)
    const editPOSData=useEditPOSData()


    async function fetch(){
        return new Promise((resolve, reject)=>{
            get(undefined,{
                onSuccess:(response:any)=>{
                    editPOSData.value= response
                    resolve(resolve)
                },
                onError:(error:any)=>{
                    reject(error)
                }
            })
        })
    }
    return {fetch, editPOSData}
}

export default defineNuxtRouteMiddleware(async (to, from)=>{
    const {fetch} = fetchEditPOSPageData(Number(to.params.id))

    try {
        await fetch()
    }
    catch(error:any){
        return navigateTo('/store')
    }
})